import { Icon, type IconProps } from "@tokenterminal/ui/Icon/Icon"
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  type HTMLAttributes,
} from "react"
import { twMerge } from "tailwind-merge"

export type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  color?: "neutral" | "green" | "yellow" | "red" | "cyan" | "violet"
  size?: "xsmall" | "small" | "medium" | "large"
  /** The emphasis of the badge. `solid` is the most prominent, `outline` is the least. */
  variant?: "solid" | "muted" | "outline"
}

const sizeLookup = {
  xsmall: "10",
  small: "12",
  medium: "14",
  large: "16",
} as const

/** A small piece of information that's useful for indicating status or importance. Avoid using multiple badges in a row, as they can be visually overwhelming.
 *
 * ```tsx
 * import { Badge } from "@tokenterminal/core/ui/badge/Badge"
 *
 * <Badge>Archived</Badge>
 * <Badge color="green">Active</Badge>
 * ```
 */
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      children,
      className,
      color = "neutral",
      size = "medium",
      variant = "muted",
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        data-color={color}
        data-size={size}
        data-variant={variant}
        className={twMerge("tt-badge", className)}
        {...props}
      >
        {Children.map(children, (child) => {
          if (isValidElement(child) && child?.type === Icon) {
            return cloneElement(child, {
              width: sizeLookup[size],
            } as Partial<IconProps>)
          }

          return child
        })}
      </div>
    )
  }
)

Badge.displayName = "Badge"
