import { Box, Row } from "@tokenterminal/ui/Box"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Link } from "@tokenterminal/ui/Link"
import { Logo } from "@tokenterminal/ui/Logo"
import { Stack } from "@tokenterminal/ui/Stack"
import { Badge } from "@tokenterminal/core/ui/messaging/badge/Badge"
import { Text } from "@tokenterminal/ui/Text"
import { footerLinkColumnsRowCss, footerLinkColumnCss } from "./Footer.css"
import { NewsletterSignup } from "./NewsletterSignup"

type Props = {}

const footerLinks = {
  company: [
    { name: "About", href: "/about" },
    { name: "Careers", href: "/careers", tag: "We're hiring!" },
    { name: "Customers", href: "/customers" },
    { name: "Contact", href: "/contact" },
    { name: "Pricing", href: "/pricing" },
    { name: "Privacy policy", href: "/privacy-policy" },
    { name: "Terms", href: "/terms" },
  ],
  products: [
    { name: "Explorer", href: "/products/explorer" },
    { name: "Sheets", href: "/products/sheets" },
    { name: "API", href: "/products/api" },
    { name: "Data Room", href: "/products/data-room" },
  ],
  explore: [
    {
      name: "API Docs",
      href: "https://docs.tokenterminal.com/reference/api-reference",
    },
    // { name: "Blog", href: "https://tokenterminal.com/resources" },
    { name: "Changelog", href: "https://tokenterminal.com/changelog" },
    { name: "Docs", href: "https://docs.tokenterminal.com/" },
    {
      name: "MVTT10F (index)",
      href: "https://www.marketvector.com/indexes/digital-assets/marketvector-token-terminal-fundamental",
    },
    {
      name: "MVTT10M (index)",
      href: "https://www.marketvector.com/indexes/digital-assets/marketvector-token-terminal-fundamental-cap-weighted-strategy",
    },
    { name: "Resources", href: "https://tokenterminal.com/resources" },
  ],
  socials: [
    { name: "X", href: "https://x.com/tokenterminal" },
    { name: "Discord", href: "https://discord.com/invite/tokenterminal" },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/token-terminal",
    },
    { name: "YouTube", href: "https://www.youtube.com/tokenterminal" },
    { name: "Podcast", href: "https://tokenterminal.com/resources/interview" },
  ],
}

const Footer = (props: Props) => {
  const currentYear = new Date().getFullYear()

  return (
    <Box style={{ padding: "20px", backgroundColor: "#000", color: "#fff" }}>
      <Box style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Row
          flexDirection={["column", "row"]}
          justifyContent="spaceBetween"
          alignItems={["flexStart", "center"]}
          marginBottom="5x"
          paddingY="3x"
        >
          <Link href="/" style={{ display: "block" }}>
            <Logo />
          </Link>
          <LinkButtonV4
            href="/explorer/listings"
            variant="outline"
            target="_blank"
          >
            Get listed
          </LinkButtonV4>
        </Row>
        <Row start className={footerLinkColumnsRowCss} alignItems="flexStart">
          {Object.entries(footerLinks).map(([section, links]) => (
            <Box key={section} className={footerLinkColumnCss}>
              <Text size="14" fontWeight="500" marginBottom="4x">
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Text>
              <Stack gap="1.5x" alignItems="flexStart">
                {links.map((link) => (
                  <Link
                    key={link.name}
                    href={link.href}
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#888",
                      marginBottom: "5px",
                      textDecoration: "none",
                    }}
                  >
                    {link.name}
                    {"tag" in link && (
                      <Badge
                        size="small"
                        color="cyan"
                        style={{ marginLeft: "4px" }}
                      >
                        {link.tag}
                      </Badge>
                    )}
                  </Link>
                ))}
              </Stack>
            </Box>
          ))}
        </Row>
        <NewsletterSignup />
        <Box
          marginTop="9x"
          marginBottom="7x"
          style={{
            textAlign: "left",
            color: "#565656",
            fontSize: "14px",
          }}
        >
          © {currentYear} Token Terminal
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
